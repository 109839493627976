











import {
  Component,
  Vue,
  Prop,
  Watch,
  Inject,
  Provide,
  Emit,
} from "vue-property-decorator";

interface Vdata {
  name: string;
}

@Component({
  components: {},
})
export default class Applicationox extends Vue {
  // data
  private data: Vdata = {
    name: "App",
  };

  // methods init
  private init(): void {
    let kd_token: any = localStorage.getItem("kd_token");
    if (kd_token) {
      try {
        let tokens: any = localStorage.getItem("kd_token");
        let tokenss: any = JSON.parse(tokens);
        // let verify = localStorage.getItem('kd_verify');
        // let verifyPage = JSON.parse(verify);
        let userInfo: any = localStorage.getItem("kd_infoUser");
        let userInfos: any = JSON.parse(userInfo);
        let companyInfo: any = localStorage.getItem("p_info");
        let authList: any = localStorage.getItem("kd_authList");
        let authLists: any = JSON.parse(authList);

        this.$store.commit("changehasToken", tokenss);
        this.$store.commit("changeToken", tokenss);
        this.$store.commit("changeParentInfo", JSON.parse(companyInfo));
        this.$store.commit("changeuserInfo", userInfos);
        // this.$store.commit('changeVerify', verifyPage);
        this.$store.commit("changeAuthList", authLists);
      } catch (err) {
        console.log(err);
      }
    }
  }

  // mounted
  private mounted(): void {
    this.init();
  }
}
