import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

interface StateItem {
  token: string;  // token
  hasToken: string;
  headerMenuId: string | number;
  authList: Array<any>;
  parentInfo: any;
  skin: string,
  userInfo: any,
  hasLogin: boolean;
}

export default new Vuex.Store({
  state: {
    /* ------------ 适配 checkToken START ------------  */
    token: '',  // token
    hasToken: '',
    headerMenuId: '',
    authList: [],
    parentInfo: {},
    skin: 'default',
    userInfo: {},
    hasLogin: false,
    /* ------------ 适配 checkToken END ------------  */
  },
  mutations: {
    /* ------------ 适配 checkToken START ------------  */
    changeToken(state:StateItem, value:string): void {
      state.token = value
    },
    changehasToken(state:StateItem, value:string): void {
      state.hasToken = value
    },
    changeAuthList(state:StateItem, value:Array<any>): void {
        state.authList = value;
    },
    changeParentInfo(state:StateItem, value:any): void {
        state.parentInfo = value
    },
    changeSkin(state:StateItem, value:string): void {
        state.skin = value
    },
    changeuserInfo(state:StateItem, value:any): void {
        state.userInfo = value
    },
    changeLogin(state:StateItem, value:boolean): void {
        state.hasLogin = value
    },
  /* ------------ 适配 checkToken END ------------  */
  },
  actions: {
  },
  modules: {
  }
})
