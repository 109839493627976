/*
 * @Author: your name
 * @Date: 2020-05-08 15:53:28
 * @LastEditTime: 2020-06-01 20:15:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \people-analysis-website\src\config\axiosProxy.js
 */

const { randomFn8, obj2str, createTimeStamp, md5, encryptByDES } = require('./encryption');
let hasToken: string | null = localStorage.getItem('kd_token') ? localStorage.getItem('kd_token') : '';
hasToken = hasToken ? JSON.parse(hasToken) : '';

// 请求拦截内容
export function axiosRequest(config: any): any {
    let hasToken: string | null = localStorage.getItem('kd_token') ? localStorage.getItem('kd_token') : '';
    hasToken = hasToken ? JSON.parse(hasToken) : '';
    const systemCode: string = 'S04SU25' // S04SU02 S09SU03

    config.headers.common['system'] = systemCode
    if (hasToken && hasToken.length > 0) {
        config.headers.common['token'] = hasToken;
    }
    // if (store.state.headerMenuId) {
    //     if (config.url.includes('websitetask/hint')) {
    //         delete config.headers.common['menuId']
    //     } else {
    //         config.headers.common['menuId'] = store.state.headerMenuId;
    //     }
    // }
    let data: any = {} //参数
    let noneData: any = {
        hasParms: false,
        hasSign: true
    };
    // get请求的话取到url上面的参数
    if (config.method === 'get' || config.method === 'delete') {
        // 可能是url传参
        if (config.url.indexOf("?") !== -1) {
            data = formatUrlData(config.url)
        } else {
            data = config.params
        }
    } else {
        data = config.data
    }
    // 判断没有参数的行为
    if (data == undefined || data == null || JSON.stringify(data) == "{}") {
        data = noneData
    }
    try {
        data = JSON.parse(JSON.stringify(data).toString().replace(/undefined/ig, ``)) //去掉参数中的undefined
        data = JSON.parse(JSON.stringify(data).toString().replace(/null/ig, ``)) //去掉参数中的null
        console.log('去掉data中的undefined', data)
        let t1 = config.headers.t1 || createTimeStamp() //不能覆盖请求头中已经存在的t1
        let t2 = randomFn8()
        let str = md5(decodeURIComponent(`[${obj2str(data)}]@systemcode=${systemCode}&t1=${t1}&t2=${t2}`))
        console.log('排序后的值', decodeURIComponent(`[${obj2str(data)}]@systemcode=${systemCode}&t1=${t1}&t2=${t2}`))
        console.log('md5后的值', str)
        // console.log(decodeURIComponent(`[${obj2str(data)}]@systemcode=${systemCode}&t1=${t1}&t2=${t2}`));
        console.log('----------------------------------------------------------------------------------')
        // 避免覆盖之前的
        config.headers["system"] = systemCode;
        //config.headers["token"] = 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJtNUtzS1d4QVJkVzZhS3lsdklGVm9nIiwic3ViIjoie1wic3lzdGVtXCI6XCJTMDlTVTAzXCIsXCJ1c2VySWRcIjpcIjEwMTAwM1wifSIsImlhdCI6MTYzNzExMDk5MSwiZXhwIjoxNjM3MTk3MzkxfQ.M8CXqVLP5pWa1pfaN6OQWGAcVrZWc-kAZmxvQuHOZxk';
        config.headers["token"] = hasToken;
        config.headers["sign"] = str;
        config.headers["t1"] = t1;
        config.headers["t2"] = t2;
        return config;
    } catch (e) {
        console.log(e)
    }
    return config;
}

// 响应拦截内容 response
export function axiosResponse(response: any, callback?: Function): any {
    if (response.data) {
        let re = /^F{1}\w{7}5{1}\d{2}$/ig;
        if (response.data.code) {
            if (response.data.msg == '会话超时') {
                window.close();
                return;
            }
            if (response.data.code.match(re)) {
                // 弹出弹窗 确认是否关闭窗口
                let timer: any = setTimeout(() => {
                    callback && callback();
                    clearTimeout(timer);
                }, 500)
            }
        }
    }
    return response;
}

// 响应拦截内容 response error
export function axiosResponseError(error: any, callback?: Function): any {
    console.log(error);
    try {
        let re: RegExp = /^F{1}\w{7}5{1}\d{2}$/ig;
        if (error.response && error.response.msg == 'Token已失效') { // re.test(error.response.data.code)
            callback && callback();
        }
    } catch (e) {
        console.log(e);

    }
    return error;
}


// axios.interceptors.request.use(function(config) {

// }, function(err) {
//     return Promise.reject(err);
// });



// axios.interceptors.request.use(function(config) {
//     console.log(142,store.state)
//     const systemCode = 'S04SU02' // S04SU02 S09SU03 S08SU03
//     config.headers["system"] = systemCode;
//     config.headers["token"] = store.state.token;
//     return config;
// }, function(err) {
//     return Promise.reject(err);
// });


function formatUrlData(value: string): string | object {
    // 1. 监测是否传递参数
    if (value.indexOf("?") === -1) {
        return {}
    }
    let str: string = value.split("?")[1];

    let attr: any = {};
    // 空值检测 错误检测
    if (!str || str.indexOf("=") === -1) {
        return attr;
    }
    // 1. 首先检测是否存在 & 符号 如果没有
    if (str.indexOf("&") === -1) {
        attr[str.split("=")[0]] = str.split("=")[1];
    } else {
        let arr: Array<string> = str.split("&");
        arr.forEach((v: string, index: number) => {
            attr[v.split("=")[0]] = v.split("=")[1];
        });
    }

    return attr;
}
