import CryptoJS from 'crypto-js'
// DES加密
export function encryptByDES(message, key, iv='01234567'){
  const keyHex = CryptoJS.enc.Utf8.parse(key) // 秘钥
  const ivStrng = CryptoJS.enc.Utf8.parse(iv) // 偏移量
  const encrypted = CryptoJS.TripleDES.encrypt(message, keyHex, {
    iv: ivStrng,
    mode: CryptoJS.mode.CBC, // 加密模式
    padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext) //  加密出来为 hex格式密文
}
// md5加密
export function md5 (ciphertext) {
  return CryptoJS.MD5(ciphertext).toString()
}

export function randomFn(len=6){
  let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  let maxPos = chars.length;
  let code = '';
  for (let i = 0; i < len; i++) {
    code += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return code
}

//object转string,用于签名计算
export function obj2str(args) {
  let keys = Object.keys(args)
  keys = keys.sort() //参数名ASCII码从小到大排序（字典序）；
  let newArgs = {}
  keys.forEach(function (key) {
    newArgs[key] = args[key]  //参数名区分大小写；
  })
  let string = ''
  for (var k in newArgs) {
    let data=''
    // 对象和数组需要转成字符串
    if(Object.prototype.toString.call(newArgs[k])=='[object Object]' || Object.prototype.toString.call(newArgs[k])=='[object Array]'){
      data=JSON.stringify(newArgs[k])
    }else {
      // 第一层需要把‘”’去掉
      data=newArgs[k].toString().replace(/"/ig, ``)
    }
    string += '&' + k + '=' +   data
  }
  string = string.substr(1)
  return string
}
//时间戳产生的函数, 当前时间以证书表达，精确到秒的字符串
export function createTimeStamp() {
  return parseInt(new Date().getTime()) + ''
}
// 8位随机数
export function randomFn8(len=8){
  let chars = '123456789';
  let maxPos = chars.length;
  let code = '';
  for (let i = 0; i < len; i++) {
    code += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return parseInt(code)
}



