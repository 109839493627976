import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { registerMicroApps, start, setDefaultMountApp } from 'qiankun'
// const microApps:Array<any> =  require('./micro-app.ts')
import microApps from './micro-app'
import { store as commonStore } from  '../../common';
import axios from './scripts/axios-proxy'; // 导入 axios 拦截

// // 注册公共数据
commonStore.commonRegister(store);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.axios = axios;
Vue.prototype.actions = microApps[0].props.actions;

const vm: Vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// 主应用加载 执行注册
interface LifeFn{
  (app:any):void;
}
interface MicroLifeCircle {
  beforeLoad: LifeFn | Array<LifeFn>;
  beforeMount: LifeFn | Array<LifeFn>;
  afterMount: LifeFn | Array<LifeFn>;
  afterUnmount: LifeFn | Array<LifeFn>;
}

const options:MicroLifeCircle = {
  beforeLoad: (app:any): void => {
    console.log('before load app.name====>>>>>', app.name)
  },
  beforeMount: [
    (app:any): void => {
      console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
    }
  ],
  afterMount: [
    (app:any): void => {
      console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
    }
  ],
  afterUnmount: [
    (app:any): void => {
      console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
    }
  ]
}
console.log(49,microApps);
registerMicroApps(microApps, options as any);

// setDefaultMountApp('/sub-collect');
start()

export default vm; // 导出 vm
