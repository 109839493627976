
import { sdk } from '../../../common';
import axios from 'axios';
let vm:any = '';

(async () => {
  const Vm = await import('../main');
  vm = Vm.default; // 获取 vm
})()


// 添加请求拦截器
axios.interceptors.request.use(function (config:any): any {
    return sdk.axiosRequest(config);
  }, function (error:any) : Promise<any>  {
    return Promise.reject(error);
});


// 添加响应拦截器
axios.interceptors.response.use(function (response:any): any {
    return sdk.axiosResponse(response,(): void => {
      // 处理 Token已失效
      vm.$confirm('身份令牌失效，请重新进入系统！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
          localStorage.clear();
          window.close();
          window.location.href = "about:blank";
      }).catch(() => {});
    });
  }, function (error:any) : Promise<any> {
    return sdk.axiosResponseError(error);
});

export default axios;
