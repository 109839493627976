// import { AnyARecord } from 'dns';
import { devState, localState, testState, prodState } from './state'
const packageJson = require('../../../package.json'); // 导入最外层 package.json
const { serverConfig } = packageJson;

// 获取初始化的state
let initState:any = {};
switch(serverConfig.env){
  case 'local':
    initState = localState;
  break;
  case 'dev':
    initState = devState;
  break;
  case 'test':
    initState = testState;
  break;
  case 'prod':
    initState = prodState;
  break;
}

function registerCommonModule (store:any, props:any = {}) {

    if (!store || !store.hasModule) {
      return;
    }

    // 将父应用的数据存储到子应用中，命名空间固定为 common
    if (!store.hasModule('common')) {
      const commonModule = {
        namespaced: true,
        state: initState,
        actions: {
          initCommonState({commit}:any,payload:any){
            commit('setCommonState', payload);
          }
        },
        mutations: {
          setCommonState(state:any,payload:any){
            state = Object.assign(state, payload);
          }
        },
      };
      store.registerModule('common', commonModule);
    } else {
      // 每次mount时，都同步一次父应用数据
      store.dispatch('common/initCommonState', initState);
    }
  };
  
  
  export default registerCommonModule; // 导出注册方法 react、angular项目使用
  export { initState }; // 导出当前的状态 非vue项目使用