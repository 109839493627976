import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    // component: Home
    redirect: { name: 'Container' }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/token',
    name: 'Token',
    component: () => import(/* webpackChunkName: "checkToken" */ '@/views/checkToken/checkToken.vue'),
  },
  {
    path: '/container',
    name: 'Container',
    component: () => import(/* webpackChunkName: "container" */ '@/views/container/container.vue'),
    redirect: { name: 'event-push' }, // 默认去到事件推送页面
    children: [
      // 事件推送
      {
        path: 'event-push',
        name: 'event-push',
        component: () => import(/* webpackChunkName: "event-push" */ '@/views/container/event-push/index.vue')
      },
      // 视图搜索
      {
        path: 'view-search',
        name: 'view-search',
        component: () => import(/* webpackChunkName: "view-search" */ '@/views/container/view-search/index.vue')
      },
      // 我的收藏
      {
        path: '/sub-collect/collect-page',
        name: 'sub-collect',
        component: () => import(/* webpackChunkName: "my-collect" */ '@/views/container/micro-page/my-collect.vue'),
        meta: { isMoicroApp: true, name: 'sub-collect' }
      },
      // 系统设置
      {
        path: '/sub-setting',
        name: 'sub-setting',
        // component: () => import(/* webpackChunkName: "setting" */ '@/views/container/micro-page/setting.vue'),
        component: () => import(/* webpackChunkName: "setting" */ '@/views/container/micro-page/setting-push.vue'),
        redirect: { name: 'push' },
        meta: { isMoicroApp: true },
        children: [
          // 系统设置 事件推送
          {
            path: '/sub-setting/setting-event-push',
            name: 'push',
            // component: () => import(/* webpackChunkName: "setting" */ '@/views/container/micro-page/setting-push.vue'),
            meta: { isMoicroApp: true, name: 'sub-setting', title: "事件推送" },
          },
          // 系统设置 任务布控
          {
            path: '/sub-setting/setting-task-control',
            name: 'task',
            // component: () => import(/* webpackChunkName: "setting" */ '@/views/container/micro-page/setting-task.vue'),
            meta: { isMoicroApp: true, name: 'sub-setting', title: "任务布控" },
          },
          // 系统设置 样本库
          {
            path: '/sub-setting/setting-sample-library',
            name: 'library',
            // component: () => import(/* webpackChunkName: "setting" */ '@/views/container/micro-page/setting-library.vue'),
            meta: { isMoicroApp: true, name: 'sub-setting', title: "样本库" },
          },
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
