const packageJson = require('../../../package.json'); // 导入最外层 package.json
const { serverConfig } = packageJson;


const local = {
    domainStatus: 'local',
    loginServer: 'http://121.201.107.114:18512/user-auth/', //登录服务
    // loginServer: 'http://192.168.1.94:18512/user-auth/',
    userServer: 'http://121.201.107.114:18512/user-service/', // 用户新服务
    //
    // qbServer: 'http://192.168.1.111:18053/',
    // // qbServer: 'http://34c34b73.vip.cpolar.cn/',
    // loginServer: 'http://121.201.107.114:19126/', // 登录新服务
    // userServer: 'http://121.201.107.114:18512/', // 用户新服务

    // loginServer: 'https://msvc.szkedun.cn/api/user-auth/', // 登录新服务
    // userServer: 'https://msvc.szkedun.cn/api/user-service/', // 用户新服务
    // qbServer: 'https://msvc.szkedun.cn/api/oversea/', // QB系统后台服务
    //uviewServer: `http://192.168.1.111:18053/`, // 云视图后台地址
    // uviewServer: 'http://192.168.31.10:18054/', // 云视图后台地址
    uviewServer: 'http://192.168.1.109:18054/', // 云视图后台地址
    obsServer: "https://obs.cn-north-4.myhuaweicloud.com",
    WEBSITE_URL: `//${serverConfig.host}:${serverConfig['main-app-port']}/`, // 访问地址
    SUB_COLLECT_WEBSITE_URL: `//${serverConfig.host}:${serverConfig['sub-collect-app-port']}/sub-collect/`, // 收藏子应用访问地址
    SUB_SETTING_WEBSITE_URL: `//${serverConfig.host}:${serverConfig['sub-setting-app-port']}/sub-setting/`, // 设置子应用访问地址
}

const dev = {
    domainStatus: 'dev',
    // loginServer: 'http://121.201.107.114:18512/user-auth/', //登录服务
    // userServer: 'http://121.201.107.114:18512/user-service/', // 用户新服务
    qbServer: 'http://121.201.107.121:18053/', // QB系统后台服务
    // qbServer: 'http://192.168.1.111:18053/',
    // qbServer: 'http://34c34b73.vip.cpolar.cn/',
    loginServer: 'http://121.201.107.121:19126/', // 登录新服务
    userServer: 'http://121.201.107.121:18512/', // 用户新服务
    // uviewServer: `http://121.201.107.121:18054/`, // 云视图后台地址
    uviewServer: `http://192.168.1.109:18054/`, // 云视图后台地址
    obsServer: "https://obs.cn-north-4.myhuaweicloud.com",
    WEBSITE_URL: '//192.168.1.193:8880/', // 访问地址
    SUB_COLLECT_WEBSITE_URL: '//192.168.1.193:8881/subapp/sub-collect/', // 收藏子应用访问地址
    SUB_SETTING_WEBSITE_URL: '//192.168.1.193:8882/subapp/sub-setting/', // 设置子应用访问地址
}

const test = {
    domainStatus: 'test',
    loginServer: 'http://121.201.107.121:18512/user-auth/', // 登录新服务
    userServer: 'http://121.201.107.121:18512/user-service/', // 用户新服务
    qbServer: 'http://121.201.107.121:18512/oversea/', // QB系统后台服务
    // uviewServer: 'http://121.201.107.121:18054/', // 云视图后台地址
    uviewServer: 'http://121.201.107.121:18512/uview/', // 云视图后台地址
    obsServer: "https://obs.cn-north-4.myhuaweicloud.com",
    WEBSITE_URL: 'http://121.201.107.121:18086/', // 访问地址
    SUB_COLLECT_WEBSITE_URL: '//121.201.107.121:18086/subapp/sub-collect/', // 收藏子应用访问地址
    SUB_SETTING_WEBSITE_URL: '//121.201.107.121:18086/subapp/sub-setting/', // 设置子应用访问地址
}

const prod = {
    domainStatus: 'prod',
    loginServer: 'https://msvc.szkedun.cn/api/user-auth/', // 登录新服务
    userServer: 'https://msvc.szkedun.cn/api/user-service/', // 用户新服务
    qbServer: 'https://msvc.szkedun.cn/api/oversea/', // QB系统后台服务
    obsServer: "https://obs.cn-north-4.myhuaweicloud.com",
    uviewServer: 'https://msvc.szkedun.cn/api/uview/', // 云视图后台地址 http://121.201.107.99:28053/
    WEBSITE_URL: '//uview.szkedun.cn/', // 访问地址
    SUB_COLLECT_WEBSITE_URL: '//uview.szkedun.cn/subapp/sub-collect/', // 收藏子应用访问地址
    SUB_SETTING_WEBSITE_URL: '//uview.szkedun.cn/subapp/sub-setting/', // 设置子应用访问地址
}


const commonState = {
    testToken: ``,
}

const localState = Object.assign({}, local, commonState)
const devState = Object.assign({}, dev, commonState)
const testState = Object.assign({}, test, commonState)
const prodState = Object.assign({}, prod, commonState)

module.exports = {
    localState, devState, testState, prodState
};
