const { initState } =  require('../../common/src/store/common-register');
import { initGlobalState, MicroAppStateActions } from 'qiankun';

interface MicroArrItem {
  name: string; // 子应用名
  entry: string; // 入口
  activeRule: string; // 激活的路由
  container: string; // 挂载的盒子
}

// 子应用注册信息
const microApps:Array<MicroArrItem> = [
  {
    name: 'sub-collect',
    // entry: process.env.VUE_APP_SUB_COLLECT,
    entry: initState.SUB_COLLECT_WEBSITE_URL,
    activeRule: '/sub-collect',
    container: '#container-page-collect', // 子应用挂载的div
  },
  {
    name: 'sub-setting',
    // entry: process.env.VUE_APP_SUB_SETTING,
    entry: initState.SUB_SETTING_WEBSITE_URL,
    activeRule: '/sub-setting/',
    container: '#container-page-setting', // 子应用挂载的div
  }
]

// actions.onGlobalStateChange((state, prev) => {
//   // state: 变更后的状态; prev 变更前的状态
//   console.log(state, prev);
// });
// actions.setGlobalState(state);
// actions.offGlobalStateChange();

// 初始化 state
const actions: MicroAppStateActions = initGlobalState({type:'collect-detail'});


const apps = microApps.map(item => {
  return {
    ...item,
    props: {
      routerBase: item.activeRule, // 下发基础路由
      getGlobalState: '', // 下发getGlobalState方法 store.getGlobalState
      actions: actions
    }
  }
})

export default apps;
